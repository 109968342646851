.App {
  text-align: center;
  color: #29344f;
  background-color: #f7fcfe;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #76a4d9;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: radial-gradient(circle at 50% top, rgb(111, 122, 149) 0%, rgb(91, 102, 129) 5%, rgb(61, 72, 99) 20%, rgb(41, 52, 79) 42%);
  min-height: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2.6vmin);
  color: white;
}

.App-header h1 {
  font-size: inherit;
}

.App-header a {
  color: #a8ccf5;
}

.App-header h6 {
  font-size: calc(0px+2.6vmin)
}



.App-link {
  color: #a8ccf5;
  text-decoration: none;
}

.App-header li {
  display: inline-block;
  line-height: 14px;
}

.App-header h6 {
  /* font-weight: bold; */
  margin-bottom: 0;
  /* -webkit-text-stroke: 1px rgb(111, 114, 121); */
}

.App-header li a {
  text-decoration: none;
  font-size: 18px;
  line-height: 14px;
  margin: 0;
  vertical-align: super;
}

.App-header li:not(:last-of-type) {
  border-right: 1px solid rgba(255,255,255,0.2);
}

.list-group-item {
  border: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #000;
  border-radius: 6px;
}

.list-group-item.active {
  background-color: #76a4d9;
  color: #f7fcfe;
}

.text-dark {
  color: #29344f !important;
}

.text-light {
  color: #f7fcfe !important;
}

.bg-light {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #29344f !important;
}

.btn-dark {
  background-color: #29344f;
  color: #f7fcfe;
}

.btn-dark:hover {
  opacity: 0.8;
  background-color: #29344f;
}

.btn-secondary {
  background-color: #7e8595;
  color: #f7fcfe;
}

.text-info {
  color: #76a4d9 !important;
}

.btn.disabled {
  color: #f7fcfe;
  background-color: #76a4d9;
  opacity: 0.7;
  border-color: #76a4d9;
}

.alert-info {
  color: #f7fcfe;
  background-color: #76a4d9;
  border: none;
  --bs-alert-border-color: none;
}

.alert-warning {
  color: #f7fcfe;
  background-color: #D9AB76;
  border: none;
  --bs-alert-border-color: none;

}

.hr {
  background-color: #29344f;
  border-color: #29344f;
  color: #29344f;
  opacity: 0.3;
}

.form-control {
  color: #29344f;

}

@media only screen and (max-width: 600px) {
  .App-header h6 {
    font-size: 16px;
  }
  .App-header h1 {
    font-size: 26px;
    margin-bottom: 0;
  }


}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
